<template>
  <el-table
    :data="filteredReport27.finalResult"
    border
    class="h-100"
    size="small"
    v-loading="reportsStore.table_loading"
    show-summary
    :summary-method="getSummaries"
  >
    <el-table-column width="180" label="Naqd xarajatlar">
      <el-table-column
        align="center"
        width="250"
        prop="expenditure"
        label="Xarajat nomi"
      />

      <el-table-column
        align="center"
        width="250"
        prop="countragent"
        label="Tashkilot"
      />

      <el-table-column align="center" width="250" prop="total" label="Summa">
        <template slot-scope="scope">
          {{ scope.row.total | toRoundFloat }}
        </template>
      </el-table-column>

      <el-table-column
        align="center"
        width="250"
        prop="nds_summa"
        label="NDS 15%"
      >
        <template slot-scope="scope">
          {{ scope.row.nds_summa | toRoundFloat }}
        </template>
      </el-table-column>

      <el-table-column
        align="center"
        width="250"
        prop="total_withnds_summa"
        label="Itogo"
      >
        <template slot-scope="scope">
          {{ scope.row.total_withnds_summa | toRoundFloat }}
        </template>
      </el-table-column>
    </el-table-column>
  </el-table>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import { toRoundFloat } from '@/utils'

export default {
  computed: {
    ...mapState(['reportsStore']),
    ...mapGetters(['filteredReport27'])
  },

  methods: {
    getSummaries(param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = 'Itogo '
          return
        }
        const values = data.map((item) => Number(item[column.property]))
        if (!values.every((value) => isNaN(value))) {
          sums[index] = toRoundFloat(
            values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0)
          )
        } else {
          sums[index] = ''
        }
      })

      return sums
    }
  },

  filters: {
    toRoundFloat
  },

  mounted() {
    this.$nextTick(() => {
      this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Naqd xarajatlar' }])
    })
  },

  beforeDestroy() {
    this.$store.dispatch(SET_BREADCRUMB, [])
  }
}
</script>
